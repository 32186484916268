import PropTypes from 'prop-types';
import React, { useCallback } from "react";

import ConsultationFormMarketplace from '@product-site-frontend/shared/components/ConsultationFormMarketplace';
import { graphql } from "gatsby";
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Breadcrumbs from '../../../../shared/components/Breadcrumbs';
import headerImage from '../../assets/marketplace_top.jpg';
import PageBox from '../../components/PageBox';
import SEO from '../../components/SEO';
import * as styles from './single.module.css';

export const query = graphql`
    query MarketplacePageDetailQuery($strapiId: Int) {
      suppliers: strapiSuppliers(strapiId: {eq: $strapiId}) {
        name
        partnerUrl
        aboutPartner
        img {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
        }
      }
    }
`;

const DetailMarketplacePage = ({ data }) => {
  const onClickScroll = useCallback((e) => {
    e.preventDefault();

    const section = document.querySelector('#form');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <PageBox>
      <SEO pageData={{
        title: `Поставщик ${data.suppliers.name} | Arenza`,
        description: `${data.suppliers.name} - сертифицированный партнер лизинговой компании Arenza. Выбирайте оборудование от проверенных поставщиков и узнавайте, какую сумму вы можете получить на его покупку`
      }} />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            href: '/marketplace',
            text: 'Выбрать поставщика'
          },
          {
            text: `Поставщик ${data.suppliers.name}`
          }
        ]}
      />
      <div className={styles.headMarketplace} style={{ backgroundImage: `url(${headerImage})` }}>
        <div className={styles.headMarketplaceWrapper}>
          <div className={styles.headMarketplaceLogoContainer}>
            <div className={styles.headMarketplaceLogoCircle}>
              <div className={styles.headMarketplaceLogoCircleFit}>
                <img
                  alt={`Поставщик ${data.suppliers.name}`}
                  src={data.suppliers.img.localFile.childImageSharp.gatsbyImageData.images.fallback.src}
                  title={data.suppliers.name}
                />
              </div>
            </div>
          </div>
          <h1 className={styles.headMarketplaceTitle}>{data.suppliers.name}</h1>
          <div className={styles.headMarketplaceButtons}>
            <button className={styles.button} onClick={onClickScroll}>
              Получить предложение
            </button>
            <a className={styles.buttonHollow} href={data.suppliers.partnerUrl} rel="nofollow noreferrer" target="_blank">
              Выбрать оборудование
            </a>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{data.suppliers.aboutPartner}</ReactMarkdown>
      </div>
      <div className={styles.formBlock}>
        <div className={styles.formContainer}>
          <div className={styles.formTitle}>Получите спецпредложение</div>
          <div className={styles.formSubtitle}>Выберите необходимое оборудование на сайте сертифицированного партнера и узнайте какую сумму вы можете получить на покупку этого оборудования прямо сейчас</div>
          <div className={styles.formScrollTo} id="form">
            <div className={styles.formScrollToTitle}>Заполните анкету и менеджер свяжется с вами в течение 24 часов</div>
            <div>
              <ConsultationFormMarketplace />
            </div>
          </div>
        </div>
      </div>
    </PageBox>
  );
};

DetailMarketplacePage.propTypes = {
  data: PropTypes.object
};

export default DetailMarketplacePage;
